/* CARDS TEST SECTION */
/* filters */
.react__container_filter {
  display: flex;
  height: var(--width-container-filter);
  background: var(--filter-background);
  border: var(--filter-border) solid 1px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 12px 9px -8px var(--filter-shadow);
  -moz-box-shadow: 0px 12px 9px -8px var(--filter-shadow);
  box-shadow: 0px 12px 9px -8px var(--filter-shadow);
  justify-content: space-between;
}
.react__filter_items {
  display: flex;
  flex: 1;
}

.react__container_search{
  display: flex;
  margin-left: 1%;
  margin-right: 1%;
}

.react__input_filter{
  background-color: transparent;
  -webkit-appearance: none; 
  -moz-appearance: none; 
  border: none; 
  outline: none; 
  background: none;
  box-shadow: none;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}
.react__search_icon{
  width: 30px;
  background: url(/public/assets/img/icon/search.svg)no-repeat center center;
}

.react__filter_items:nth-of-type(2){
    justify-content: flex-end;
    display: flex;
}

.react__number_filters{
  padding-top:4px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
  
}
.react__container_filters{
  flex-direction: row-reverse;
  margin-right: 3%;
  display: flex;
  align-items: center;
  flex:1;
  width: 25vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.react__container_filters::-webkit-scrollbar {
  height: 15px;
}

.react__container_filters::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}

.react__add_filter{
  display: flex;
  margin-right: 3%;
  gap: 5px;
}

.react__add_filter{
  height: 20px;
  display: flex;
  color: var(--white-color);
  background-color: var(--blue-color);
  padding:4px 6px 4px 6px;
  border-radius: 20px;
  font-weight:bolder;
}

.react__close_filter{
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: var(--white-color);
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-right: 1%;
}

.react__filter_name{
  height: 20px;
  white-space: nowrap;
}

.react__filter_icon {
  background: url(/public/assets/img/icon/filters.svg) no-repeat center center;
  width: 30px;
  margin-right: 10px;
  vertical-align: middle;
}
/* cards */
.container-cards {
  margin-top: 2%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.item-card {
  border: var(--filter-border) solid 1px;
  border-radius: 10px;
  padding: 20px;
}

.test-title-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--blue-color);
}

.test-title-price h3 {
  margin: 0;
}

.test-title-price h3 > p {
  display: inline;
  margin: 0px;
  font-size: 15px;
  font-weight: lighter;
}

.test-title-price .price {
  font-size: 1.2em;
  margin: 0px 0px 20px 0;
}

.info-test {
  font-size: 12px;
  width: 70%;
  margin: 2% 0 5% 0;
}

.tags {
  font-size: 12px;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tags .tag {
  border-radius: 20px;
  padding: 2px 5px 2px 5px;
  white-space: nowrap;
}
.price-add {
  display: flex;
  justify-content: space-around;
}

.price-add button {
  display: inline-block;
  width: 100%;
  background-color: var(--blue-color);
  color: #fff;
  border-radius: 10px;
  height: 38px;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  font-size: 12px;
  vertical-align: middle;
}

.icon-price-add {
  background: url(/public/assets/img/icon/cart.svg) no-repeat center center;
  background-size: 20px;
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-right: 5px;
  vertical-align: middle;
}

.tag-green {
  background-color: #aff9a3;
  border: #87d17b solid 1px;
}

.tag-yellow {
  background-color: #fff0b5;
  border: #d7c88d solid 1px;
}

.tag-orange {
  background-color: #ffb970;
  border: #d79148 solid 1px;
}

.tag-grey {
  background-color: #d2d2d2;
  border: #b1c0c7 solid 1px;
}

@media (max-width: 1100px) {
  .container-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .container-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
