/* HEADER NAV STEP */
.nav_step {
  display: flex;
  height: var(--width-nav-step);
  width: 100%;
  border-radius: 100px;
  align-items: center;
  background-color: #002842;
  justify-content: space-between;
}

/* --progress nav-- */
.progress_step {
  z-index: 1;
  color: #fff;
  position: absolute;
  width: 0%;
  /* width: 20%; */
  /* width: 40%;   */
  /* width: 40%; */
  /* width: 50%;  */
  height: var(--width-nav-step);
  border-radius: 100px;
  background-color: var(--color-item-step);
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress_confirm {
  position: absolute;
  z-index: 2;
  height: var(--width-nav-step);
  background-color: var(--color-progress-confirmed);
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 100px;
}

.progress_confirm div {
  font-weight:bold;
  color: var(--white-color);
  height: 100%;
  display: flex;
  align-items: center; /* Centrar el contenido verticalmente */
  justify-content: center; /* Centrar el contenido horizontalmente */
  width: 100%;
}

.icon_checked_success {
  background: url(/public/assets/img/icon/checked.svg) no-repeat center center;
  background-size: 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

/* --title nav-- */
.title_nav_step {
  min-width: var(--width-step-name);
}
.title_nav_step h1 {
  color: var(--blue-color);
  margin: 0;
}
.title_nav_step p {
  margin: 0;
}

.item_nav {
  flex: 1;
  z-index: 1;
  display: flex;
  background-color: #002842;
  border-radius: 30px;
  font-weight: bold;
  height: 100%;
  justify-content: space-between;
}

.number_item_step {
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-color);
  width: 50px;
  border-radius: 50%;
}

.name_item_step {
  flex: 1;
  color: #fff;
  align-self: center;
  text-align: center;
}

.number_item_step_ok {
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-item-step-select);
  width: 53px;
  border-radius: 50%;
}

.item_nav_ok {
  background-color: var(--color-item-step);
}

@media (max-width: 767px) {
  .progress_step {
    width: 0%;
    /* width: 30%; */
    /* width: 60%; */
    /* width: 80%; */
  }
  .title_nav_step {
    text-align: center;
  }
}
