.react__container_cart {
  padding: 2%;
  background: var(--blue-color);
  border-radius: 20px;
}

.react__title_cart {
  display: flex;
}

.react__title_cart_step {
  width: 25px;
  height: 25px;
  background-color: var(--color-item-step);
  text-align: center;
  border-radius: 25px;
  margin: 0% 2% 0 3%;
}

.react__container_item_cart {
  background-color: #014564;
  padding: 0% 3% 1% 3%;
  border-radius: 20px;
}
.react__item_cart_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1vw;
}
.react_cart_item_name {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react__name_form {
  white-space: nowrap;
}
.react__name_form > .react__title_cart_step_ok {
  margin-right: 10px;
  background-color: var(--color-progress-confirmed);
  width: 25px;
  height: 25px;
  border-radius: 25px;
}
.react__name_form > .react__title_cart_step_pending {
  margin-right: 10px;
  background-color: rgb(0, 0, 0, 0.3);
  width: 25px;
  height: 25px;
  border-radius: 25px;
}
.react__icon_success {
  background-size: 25px;
  background: url(/public/assets/img/icon/checked.svg) no-repeat center center;
}

.react__cart_item_price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react__tag_cart {
  margin: 0 !important;
  color: #000;
  font-weight: normal;
}

.react__subtotal_cart {
  height: 25px;
  font-weight: bold;
}
.react__total_cart {
  font-size: 20px;
  font-weight: bold;
  height: 70px;
}

.react__cart_day_time {
  display: block;
}

.react__cart_day_time > div.react__day_time {
  margin-top: 5px;
  font-weight: bold;
}

.react__button_submit_cart {
  margin: 5% 0 5% 0;
}
.react__button_submit_cart > button {
  all: unset;
  text-align: center;
  color: var(--white-color);
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--color-item-step);
  border-radius: 25px;
  cursor: pointer;
}

.react__button_view_cart {
  display: none;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}

.react__button_view_cart > button {
  flex: 1;
  margin: 0 10px;
  all: unset;
  text-align: center;
  color: var(--white-color);
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  background-color: var(--color-item-step);
}
.react__button_view_cart > button:hover {
  background-color: var(--color-item-step-select);
}

.react__button_view_cart > button:nth-child(1) {
  background-color: var(--color-button-view-cart);
}

.react__button_view_cart > button:nth-child(1):hover {
  opacity: 0.7;
}

.react__button_submit_cart > button:hover {
  background-color: var(--color-item-step-select);
}

.react__item_cart {
  justify-content: space-between;
  position: relative;
  margin-bottom: 10px;
}

.react__cart_line {
  margin-top: 15px;
  border-bottom: gray solid 1px;
  height: 1px;
  width: 100%;
}
.react__cart_line_day_time {
  margin-top: 0;
}

.react__cart_line_show_mobile{
    display: none;
}

@media (max-width: 767px) {
  .react__button_view_cart {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }
  .react__cart_steps{
    display: none;
  }
  .react__container_cart{
    border-bottom-left-radius: 0px; 
    border-bottom-right-radius: 0px;
  }
  .react__cart_line_show_mobile{
    display: flex;
    border-top: gray solid 5px;
    border-bottom: 0;
    align-self: center;
    text-align: center;
    padding-top: 10px;
    height: 0;
    margin-bottom: 6%;
    justify-content: center;
}
}
