:root {
  --white-color: #ffffff;
  --blue-color: #01567d;
  --filter-background: #f4f4f4;
  --filter-border: #c5c5c5;
  --filter-shadow: #b4b1b1;
  --width-cart-title: 60vh;
  --width-step-name: 25vw;
  --width-nav-step: 50px;
  --width-container-filter: 60px;
  --color-progress-confirmed: #8EC240;
  --color-item-step: #F26735;
  --color-item-step-select: #fc8054;
  --color-button-view-cart: #1AA79F;
}

body {
  font-family: Open Sans, sans-serif;
}

/* CONTAINER REACT */
.react__container_app {
  margin: 1% 7% 3% 7%;
  display: grid;
  grid-template-areas:
    "header header"
    "cart test";
  grid-column-gap: 2rem; /* Espacio entre columnas */
  grid-row-gap: 2rem; /* Elimina el espacio vertical entre filas */
}

.react__header_container {
  display: flex;
  grid-column-gap: 2rem;
  grid-area: header;
  justify-content: space-between;
}

.react__cart_container {
  color: var(--white-color);
  grid-area: cart;
  width: var(--width-step-name);
}

.react__test_container {
  grid-area: test;
}

@media (max-width: 767px) {
  .react__container_app {
    margin: 1% 2% 0% 2%;
    display: grid;
    grid-template-areas:
      "header"
      "test"
      "cart";
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  .react__header_container {
    display: flex;
    flex-direction:column-reverse;
    grid-row-gap: 1rem;
  }

  .react__cart_container {
    overflow-y: auto;
    width: 96%;
    position:fixed;
    bottom: 0;
  }

  .react__cart_container::-webkit-scrollbar {
    width: 0;
  }
}
